<template>
  <div class="row">
    <div class="col-12">
      <template>
        <component
          :is="component"
          :patrolInfo.sync="patrolInfo"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fireFighting-patrol',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      patrolInfo: {
        patrolFstCd: 'PTF0000010', // PATROL_TYPE_FST_CD
        // patrolSecCd: 'PTF0000010', // PATROL_TYPE_SEC_CD
        typeDisabled: true,
      },
      component: null,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.component = () => import('@/pages/sai/patrol/patrol.vue');
    },
  }
};
</script>